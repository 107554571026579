<template>
  <SidePanel>
    <template v-slot:header>
      <span v-if="sourceType == 'feed'">
        Hosted File Inventory Source
      </span>
      <span v-else-if="sourceType == 'manual_upload'">
        Manual Upload Inventory Source
      </span>
    </template>
    <template v-slot:content>
      <div class="q-gutter-y-md">
        <div v-if="sourceType == 'feed'" class="q-gutter-y-md">
          <p>
            For hosted file inventory sources, we'll import your
            {{ $store.state.productTypeConfigs.type }}s from a file that you
            host. We will automatically do this once a day. We support various
            connection methods, file types and formatting. For more details on
            what's supported, see below.
          </p>
          <div>
            <p class="text-weight-bold">Supported File Types</p>
            <p>.csv, .tsv, .psv, or .xml</p>
            <p class="text-subtle">
              Compressed files (.zip, .gz, etc) are also supported.
            </p>
          </div>
          <div>
            <p class="text-weight-bold">Supported Connection Methods</p>
            <p>HTTPS, FTP, or SFTP</p>
          </div>
          <div>
            <p class="text-weight-bold">Supported File Formatting</p>
            <p>
              Standardized or Facebook Vehicle Feeds
              <small
                ><a
                  href="https://developers.facebook.com/docs/marketing-api/auto-ads/reference#vehicle"
                  target="_blank"
                  >See Facebook Reference</a
                ></small
              >
            </p>
          </div>
        </div>
        <div v-else-if="sourceType == 'manual_upload'" class="q-gutter-y-md">
          <p>
            For manual upload inventory sources, we'll import your
            {{ $store.state.productTypeConfigs.type }}s from a file that you
            upload. You can upload a new file at anytime. However, manual
            uploads are limited to 50MB. For more details on what's supported,
            see below.
          </p>
          <div>
            <p class="text-weight-bold">Supported File Types</p>
            <p>.csv, .tsv, .psv, or .xml</p>
          </div>
        </div>

        <p>
          Below is a list of the standardized fields that we support in an
          inventory source file.
          <a href="javascript:void(0)">
            Download Example File
            <q-menu anchor="bottom left" self="top left">
              <q-item clickable>
                <q-item-section>
                  <a
                    href="https://carscience.s3.amazonaws.com/carscience-standardized-example-file.csv"
                    download
                    target="_blank"
                    >CSV Example File</a
                  >
                </q-item-section>
              </q-item>
              <q-item clickable>
                <a
                  href="https://carscience.s3.amazonaws.com/carscience-standardized-example-file.xml?v=1"
                  download
                  target="_blank"
                  >XML Example File</a
                >
              </q-item>
            </q-menu>
          </a>
        </p>

        <q-table
          flat
          bordered
          :rows="standardizedFieldsTableRows"
          :columns="standardizedFieldsTableColumns"
          row-key="type"
          hide-bottom
          wrap-cells
          table-header-class="bg-gray-very-light"
          class="q-my-md"
          style="pointer-events: none"
          :pagination="{
            page: 1,
            rowsPerPage: 300
          }"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td v-for="col in props.cols" :key="col.name" :props="props">
                <span v-html="col.value" />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </template>
  </SidePanel>
</template>

<script>
import SidePanel from "@/components/UI/SidePanel";

export default {
  name: "AdvertiserInventorySourceTypeInfoSidePanel",
  components: { SidePanel },
  props: {
    sourceType: {
      type: String,
      default: "feed"
    }
  },
  data() {
    return {
      standardizedFieldsTableColumns: [
        {
          name: "field",
          field: "field",
          label: "Field",
          required: true,
          align: "left",
          sortable: false
        },
        {
          name: "required",
          field: "required",
          label: "Required/Optional",
          required: true,
          align: "left",
          sortable: false
        },
        {
          name: "description",
          field: "description",
          label: "Description",
          required: true,
          align: "left",
          sortable: false
        }
      ],
      standardizedFieldsTableRows: [
        {
          field: "<pre>vin</pre>",
          required: "Required",
          description:
            "The Vehicle Identification Number (VIN) for the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>year</pre>",
          required: "Required",
          description: "The year of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>make</pre>",
          required: "Required",
          description:
            "The make of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>model</pre>",
          required: "Required",
          description:
            "The model of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>mileage</pre>",
          required: "Required",
          description:
            "The mileage of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>price</pre>",
          required: "Required",
          description:
            "The listing price of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>image_urls<pre/>",
          required: "Required",
          description:
            "A pipe or comma delimited list of the images of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>destination_url<pre/>",
          required: "Required",
          description:
            "The destination url of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>bid_value<pre/>",
          required: "Optional",
          description:
            "A bid value for the vehicle. If not set, the bid method & bid value set on the connected advertiser account will be used.<br /><small>Type: Float</small>"
        },
        {
          field: "<pre>postal_code<pre/>",
          required: "Optional",
          description:
            "The postal code the vehicle is located in. If not set, the inventory source's default postal code will be used.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>payable_radius<pre/>",
          required: "Optional",
          description:
            "The targeting radius, in miles, the vehicle will be served in. If not set, the inventory source's default targeting radius will be used.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>vehicle_id<pre/>",
          required: "Optional",
          description:
            "An unique identifier for the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>condition<pre/>",
          required: "Optional",
          description:
            "The condition of the vehicle. New or Used.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>body_type<pre/>",
          required: "Optional",
          description:
            "The body type of the vehicle. Sedan, SUV, Truck, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>trim<pre/>",
          required: "Optional",
          description:
            "The model specific trim of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>exterior_color<pre/>",
          required: "Optional",
          description:
            "The color of the vehicle's exterior. Black, Silver, Red, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>interior_color<pre/>",
          required: "Optional",
          description:
            "The color of the vehicle's interior. Black, Tan, Gray, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>features<pre/>",
          required: "Optional",
          description:
            "A pipe or comma delimited list of the features of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>description<pre/>",
          required: "Optional",
          description:
            "A description of the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>transmission<pre/>",
          required: "Optional",
          description:
            "The type of transmission the vehicle has. Automatic, Manual, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>engine_type<pre/>",
          required: "Optional",
          description:
            "The type of engine the vehicle has. 4 Cylinder, 6 Cylinder, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>drivetrain<pre/>",
          required: "Optional",
          description:
            "The drivetrain of the vehicle. AWD, 4WD, RWD, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>fuel_type<pre/>",
          required: "Optional",
          description:
            "The type of fuel the vehicle uses. Gasoline, Electric, Hybrid, etc.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>num_doors<pre/>",
          required: "Optional",
          description:
            "The number of doors on the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>highway_mpg<pre/>",
          required: "Optional",
          description:
            "The highway MPG of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>city_mpg<pre/>",
          required: "Optional",
          description:
            "The city MPG of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>stock_number<pre/>",
          required: "Optional",
          description:
            "The given stock number for the vehicle.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>msrp<pre/>",
          required: "Optional",
          description:
            "The manufacturer's suggested retail price (MSRP) of the vehicle.<br /><small>Type: Int</small>"
        },
        {
          field: "<pre>dealer_id<pre/>",
          required: "Optional",
          description:
            "An unique identifier for the vehicle's dealership.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>dealer_name<pre/>",
          required: "Optional",
          description:
            "The name of the vehicle's dealership.<br /><small>Type: String</small>"
        },
        {
          field: "<pre>dealer_phone<pre/>",
          required: "Optional",
          description:
            "The phone number for the vehicle's dealership.<br /><small>Type: Int <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>dealer_email<pre/>",
          required: "Optional",
          description:
            "The email address for the vehicle's dealership.<br /><small>Type: String <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>dealer_url<pre/>",
          required: "Optional",
          description:
            "The website url for the vehicle's dealership.<br /><small>Type: String <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>dealer_address<pre/>",
          required: "Optional",
          description:
            "The street address the vehicle's dealership is located at.<br /><small>Type: String <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>dealer_city<pre/>",
          required: "Optional",
          description:
            "The city the vehicle's dealership is located in.<br /><small>Type: String <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>dealer_state<pre/>",
          required: "Optional",
          description:
            "The state the vehicle's dealership is located in.<br /><small>Type: String <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        },
        {
          field: "<pre>date<pre/>",
          required: "Optional",
          description:
            "The date the vehicle was listed for sale.<br /><small>Type: Date <span class='text-subtle q-ml-xs'>Requires dealer_id.</span></small>"
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
