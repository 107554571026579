<template>
  <q-dialog
    :ref="$options.name"
    class="side-panel-modal"
    position="right"
    full-height
  >
    <q-card square style="width: 800px; max-width: 80vw;">
      <q-card-section class="flex items-center justify-between">
        <div>
          <h5>
            <span v-html="header" />
            <slot name="header" />
          </h5>

          <p
            v-if="subHeader.length > 0"
            v-html="subHeader"
            class="text-font-secondary"
          />
        </div>
        <CloseButton @click="hide" />
      </q-card-section>

      <q-separator inset />

      <q-card-section>
        <slot name="content" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import CloseButton from "@/components/UI/CloseButton";

export default {
  name: "SidePanel",
  components: { CloseButton },
  props: {
    header: {
      type: String,
      default: ""
    },
    subHeader: {
      type: String,
      default: ""
    }
  },
  data() {},
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
