<template>
  <div>
    <div
      :class="{
        'row q-gutter-x-md': !vertical,
        'column q-gutter-y-md': vertical
      }"
    >
      <q-card
        v-for="(option, i) in options"
        :key="'optionOption_' + i"
        @click="
          option.disabled || disabled
            ? null
            : $emit('update:modelValue', option.value)
        "
        :v-ripple="!(option.disabled || disabled)"
        flat
        bordered
        class="selection-tile col cursor-pointer relative-position"
        :class="{
          '--active': option.value === modelValue,
          '--disabled': option.disabled || disabled
        }"
      >
        <transition appear name="fade" mode="out-in">
          <q-icon
            v-if="!hideSelectionIcon && option.value === modelValue"
            name="sym_r_check_circle"
            size="sm"
            color="primary"
            style="position: absolute; top: 8px; right: 8px;"
            class="--icon-filled"
          />
        </transition>

        <slot v-if="customOptionsContent" name="option" v-bind="option" />
        <q-card-section
          v-else
          :class="{
            'column items-center': !vertical,
            'row items-center': vertical
          }"
        >
          <q-icon
            v-if="option.icon"
            :name="option.icon"
            :color="option.value === modelValue ? 'primary' : 'subtle'"
            size="38px"
            :class="{
              'q-mb-md': !vertical,
              'q-mr-md': vertical
            }"
          />
          <div
            v-else-if="option.iconCustomHtml"
            v-html="option.iconCustomHtml"
            :class="{
              'fill-primary': option.value === modelValue,
              'fill-subtle': option.value !== modelValue
            }"
          />
          <div class="full-width">
            <b v-html="option.label" />
            <p v-if="option.description" class="text-font-secondary">
              <span v-html="option.description" />
              <span v-if="option.descriptionAction" class="q-ml-xs">
                <a
                  href="javascript:void(0)"
                  @click="option.descriptionAction"
                  >{{ option.descriptionActionLabel }}</a
                >
              </span>
            </p>
          </div>
          <Link
            v-if="option.link"
            :to="option.link"
            :label="option.linkLabel"
            class="q-mt-md"
          />
        </q-card-section>

        <q-tooltip
          v-if="'tooltip' in option && option.tooltip.length > 0"
          anchor="top middle"
          self="bottom middle"
        >
          <span v-html="option.tooltip" />
        </q-tooltip>
      </q-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectionTiles",
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    hideSelectionIcon: {
      type: Boolean,
      default: false
    },
    customOptionsContent: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"]
};
</script>

<style scoped lang="scss">
.selection-tile {
  @include transition();

  &.--active {
    border-color: color(primary);
  }
  &.--disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }
}
</style>
