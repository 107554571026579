<template>
  <confirmation-modal
    :ref="$options.name"
    :sub-header="descriptor"
    :message="
      'Are you sure you want to delete <b>' +
        descriptor +
        '</b>? This action cannot be undone.'
    "
    header="Confirm Deletion"
    button-label="Delete"
    @ok="handleOkClick"
    :force-submitting="submitting"
  />
</template>

<script>
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";

export default {
  name: "ConfirmationModalDelete",
  components: { ConfirmationModal },
  props: {
    descriptor: {
      type: String,
      required: true
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    handleOkClick() {
      this.$emit("ok", this);
    }
  }
};
</script>

<style scoped lang="scss"></style>
